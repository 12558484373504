import { getAppConfig } from '../core'
import { AppConfigInterface } from './app'
import { ContentConfigInterface } from './content'

const RAConfig = getAppConfig<AppConfigInterface, ContentConfigInterface>()

const EnvConfig = RAConfig.env
const EnvConfigAssetAllocation = EnvConfig.assetAllocationInteractive
const EnvConfigLoginLib = EnvConfig.loginLib

const ApiConfig = RAConfig.api
const ApiConfigServices = ApiConfig.services

const AppConfig = RAConfig.app

const ContentConfig = RAConfig.content

const AnalyticsConfig = RAConfig.analytics

const TagsConfig = RAConfig.tags

export default RAConfig
export {
  AnalyticsConfig,
  ApiConfig,
  ApiConfigServices,
  AppConfig,
  ContentConfig,
  EnvConfig,
  EnvConfigAssetAllocation,
  EnvConfigLoginLib,
  TagsConfig,
}
