import React, { FC } from 'react'
import Svg, { SvgProps } from '..'

interface RASailProps extends SvgProps {
  width?: string
  height?: string
}

const RASail: FC<RASailProps> = ({ style, width = '24px', height = width }) => {
  return (
    <Svg
      viewBox="0 0 54 54"
      className="ra-sail-svg"
      style={style}
      width={width}
      height={height}
    >
      <path d="M45.7,41.24h-4.21c-.26-.71-.36-1.47-.28-2.22v-5.37h-7.68v7.58h-3.94v-7.58h-4.41c-1.98,0-2.78,.99-2.78,2.86v2.11c.06,.9-.1,1.79-.49,2.61h-4.29v-.58c.53,0,.84-.73,.84-1.73v-2.62c0-2.32,.49-3.69,2.55-4.25-1.96-.57-3.25-2.43-3.12-4.46,0-3.23,2.13-6.53,7.6-6.53,2.44-.21,4.79,.96,6.1,3.02,1.28-2.04,3.58-3.21,5.99-3.05,4.69,0,7.34,2.87,7.34,6.75v11.41c0,.72,.36,1.47,.81,1.47v.57ZM0,54H54V0C24.18,0,0,24.18,0,54H0M25.24,25.04c-2.25,0-3.4,1.05-3.4,2.88,0,2.13,1.48,2.63,3.14,2.63h4.6v-2.2c0-2.48-1.97-3.31-4.34-3.31m12.12-.14c-1.92-.19-3.63,1.22-3.81,3.14,0,.09-.01,.18-.02,.27v2.24h7.69v-2.37c0-2.31-1.75-3.28-3.86-3.28" />
    </Svg>
  )
}

export default RASail
