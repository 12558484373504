import { CSSProperties } from 'react'

const defaultGap: CSSProperties = {
  gap: 8,
}

export const flexRowCenter: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

export const flexColumnCenter: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

export const flexRowSpaceBetweenCenter: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...defaultGap,
}

export const fontWeight500: CSSProperties = {
  fontWeight: 500,
}
