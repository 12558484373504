import { AnalyticsConfigInterface } from './analytics'
import { ApiConfigInterface } from './api'
import { AppConfigInterface } from './app'
import { EnvConfigInterface } from './env'
import { TagsConfigInterface } from './tags'

const RAConfig = (window as any).RA

/**
 * Gets configuration common to all RACore bundle modules.
 *
 * @returns
 */
export function getCoreConfig(): {
  analytics: AnalyticsConfigInterface
  api: ApiConfigInterface
  app: AppConfigInterface
  env: EnvConfigInterface
  tags: TagsConfigInterface
} {
  const { analytics, api, app, env, tags } = RAConfig.config
  return { analytics, api, app, env, tags }
}

/**
 * Gets configuration for an app, either AAI or SBI currently.
 *
 * @returns
 */
export function getAppConfig<APP, CONTENT>(): {
  analytics: AnalyticsConfigInterface
  api: ApiConfigInterface
  app: APP
  content: CONTENT
  env: EnvConfigInterface
  tags: TagsConfigInterface
} {
  const { analytics, api, app, content, env, tags } = RAConfig.config
  return { analytics, api, app, content, env, tags }
}
