import { AAILogo } from '@ra/lib-common/components/svg/logo'
import React, { useEffect } from 'react'
import { flexColumnCenter, flexRowCenter } from './styles/inline'

export function Preload() {
  const [style, setStyle] = React.useState({
    opacity: 0,
    transform: 'scale(0.9)',
  })

  useEffect(() => setStyle({ opacity: 1, transform: 'scale(1)' }), [])

  return (
    <div style={flexRowCenter}>
      <div
        style={{
          ...flexColumnCenter,
          gap: 24,
          transition: 'opacity 800ms ease-out, transform 200ms ease-out',
          ...style,
        }}
      >
        <AAILogo width="min(320px, 60vw)" style={{ fill: 'white' }} />
      </div>
    </div>
  )
}
