/**
 * Some scripts need to be setup before everything else imports. Place that
 * configuration here:
 */

/**
 * Inject the global stylesheet before everything else. In production, this is
 * extracted into a separate CSS file.
 */
import './styles/application'

/** What-input helps us determine the method of input a user is providing. */
import 'what-input'

/**
 * We use BigNumber to correctly calculate asset weights for incredibly precise
 * values. Otherwise the numbers do not add up due to floating point imprecision!
 */
import BigNumber from 'bignumber.js'

BigNumber.config({
  DECIMAL_PLACES: 12,
  // We aren't equipped to handle errors if BigNumber hits too much
  // precision. Ignore them.
  ERRORS: false,
})

/**
 * Asset Allocation uses session storage by default. This allows the app to
 * track a user's custom portfolios before they have an account.
 */
import { setDefaultStorageType, StorageTypes } from '@ra/lib-common/storage'

setDefaultStorageType(StorageTypes.SESSION_STORAGE)
