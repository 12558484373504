/**
 * Returns an object with a `dangerouslySetInnerHTML` property set with the
 * value. Spread into an element's props to set inner HTML.
 *
 * REMEMBER: Do not use unless you are certain the value string is clean and
 * unable to pose a XSS risk.
 *
 * @param value HTML string value.
 * @returns
 */
export function dangerousHtml(value: string): {
  dangerouslySetInnerHTML: { __html: string }
} {
  return {
    dangerouslySetInnerHTML: {
      __html: value,
    },
  }
}

/** Replaces `(` with `<span>(` and `)` with `)</span>`. */
export function spanWrapParens(str: string) {
  return str.replace(/\(/, '<span>(').replace(/\)/, ')</span>')
}
