import RAConfig, { EnvConfigLoginLib } from '@ra/lib-config'
import {
  CaptureFunctionInterface,
  LaunchRuleInterface,
  LaunchRuleMap,
  TrackRuleContextInterface,
} from '@ra/lib-config/analytics'
import jQuery from 'jquery-lite'
import isString from 'lodash/isString'
import keys from 'lodash/keys'
import { debugLog } from '../dev/debug'
import { FunctionMap, StringMap } from '../types/global'
import {
  AnalyticsIntegrationConfigInterface,
  AnalyticsWindowInterface,
  TinyTrackRuleInterface,
} from './types'

/**
 * Initializes integration with Adobe Analytics and Launch rules to track
 * interactions.
 *
 * @param config
 */
export function initAnalyticsIntegration(
  config: AnalyticsIntegrationConfigInterface
) {
  const { launchEventRuleMap: eventRuleMap, getEvarMap, getPropMap } = config

  const ruleNames = keys(eventRuleMap)
  const rules = ruleNames.reduce((map, ruleName) => {
    const event = eventRuleMap[ruleName]
    const attribute = `data-track-${event}`

    map[ruleName] = {
      event,
      attribute,
      selector: `*[${attribute}]`,
      trackInteraction,
      getEvarMap,
      getPropMap,
    }

    return map
  }, {} as LaunchRuleMap)

  RAConfig.analytics.LaunchRules = rules
}

/**
 * Tracks an interaction captured by a Launch rule.
 *
 * @param elem Element that was clicked or changed.
 * @param _event Event raised; not used currently, here just in case needed in
 *   future.
 * @returns
 */
function trackInteraction(elem: HTMLElement, _event: any): boolean {
  const $elem = jQuery(elem)
  const launchRule = findLaunchRule($elem)

  if (launchRule) {
    const { attribute } = launchRule

    const attrConfig: TinyTrackRuleInterface = JSON.parse($elem.attr(attribute))
    const { n: name, m: modifiersWithPlaceholders = [] } = attrConfig

    const modifiers = modifiersWithPlaceholders.map((m) =>
      m in GlobalPlaceholders ? GlobalPlaceholders[m]($elem) : m
    )

    return captureAndSend(launchRule, { name, modifiers }, elem)
  } else {
    return false
  }
}

/**
 * Tracks an interaction manually for scenarios when the capture is not
 * triggered from a Launch rule and location (data-loc) and modifiers
 * (data-ltype) are primarily sourced from `analytics.config.json`. Used by PDF
 * Viewer and when the interaction must be tracked after a successful async
 * process like logging in.
 */
export function trackInteractionOn(
  elemOrSelector: string | HTMLElement,
  launchRuleName: string,
  trackRuleName: string,
  contextData: StringMap = {}
) {
  const launchRule = findLaunchRule(launchRuleName)

  if (launchRule) {
    const { trackRules } = RAConfig.analytics
    const { location, modifiers } = trackRules[trackRuleName]

    const trackRuleContext = {
      name: trackRuleName,
      location,
      contextData,
      modifiers: resolveModifiers(modifiers, contextData),
    }

    const elem = isString(elemOrSelector)
      ? jQuery(elemOrSelector)
      : [elemOrSelector]

    if (elem.length === 0) {
      throw new Error(`selector did not find an element: ${elemOrSelector}`)
    }

    captureAndSend(launchRule, trackRuleContext, elem[0])
  }
}

/**
 * Tracks an interaction manually for scenarios when the capture is not
 * triggered from a Launch rule and location (data-loc) and modifiers
 * (data-ltype) will be passed in with `trackRuleContext` instead of being
 * sourced from `analytics.config.json`.
 */
export function trackInteractionWith(
  elemSelector: string,
  launchRuleName: string,
  trackRuleContext: TrackRuleContextInterface
) {
  const launchRule = findLaunchRule(launchRuleName)

  if (launchRule) {
    const elem = jQuery(elemSelector)
    if (elem.length === 0) {
      throw new Error(`selector did not find an element: ${elemSelector}`)
    }

    captureAndSend(launchRule, trackRuleContext, elem[0])
  }
}

/**
 * Captures relevant metadata for an interaction and sends a beacon to Adobe
 * Analytics.
 *
 * @param launchRule
 * @param trackRuleContext
 * @param elem
 * @returns
 */
function captureAndSend(
  launchRule: LaunchRuleInterface,
  trackRuleContext: TrackRuleContextInterface,
  elem?: HTMLElement
) {
  debugLog('Analytics', () => [
    'captureAndSend',
    { launchRule, trackRuleContext, elem },
  ])

  const { globalCapture, trackRules } = RAConfig.analytics
  const {
    s,
    _satellite: _s,
    digitalData,
  }: AnalyticsWindowInterface = window as any

  const { name } = trackRuleContext
  const { capture = {}, events = [] } = trackRules[name]

  const { evarMap, propMap } = getGlobalEvarPropMaps(
    launchRule,
    trackRuleContext
  )

  const { evars: globalEvars = [], props: globalProps = [] } = globalCapture
  const { evars = [], props = [] } = capture

  const toPrefixed = (prefix: string) => (num: number) => `${prefix}${num}`
  const chosenEvars = globalEvars.concat(evars || []).map(toPrefixed('eVar'))
  const chosenProps = globalProps.concat(props || []).map(toPrefixed('prop'))
  const log: StringMap = {}

  chosenEvars.forEach((e) => (s[e] = log[e] = evarMap[e]))
  chosenProps.forEach((p) => (s[p] = log[p] = propMap[p]))

  s.pageName = log.pageName = digitalData.page.pageInfo.pageName
  s.linkTrackVars = log.linkTrackVars = chosenEvars
    .concat(chosenProps)
    .join(',')

  s.events =
    log.events =
    s.linkTrackEvents =
    log.linkTrackEvents =
      events.map(toPrefixed('event')).join(',')

  _s.logger && _s.logger.log('🚀 On tracker', { ...log, s })
  debugLog('Analytics', () => [
    'captureAndSend',
    '🚀 On tracker',
    { ...log, s },
  ])

  const isClickAction = events.includes(9)
  const isDownload = events.includes(1)
  const isExit = false // Do we use this?

  const values = {
    linkType: isDownload ? 'd' : isExit ? 'e' : isClickAction ? 'o' : 'o',
    linkName: name,
  }

  s.tl(elem, values.linkType, values.linkName)

  _s.logger && _s.logger.log('🚀 Sent beacon', { elem, ...values })
  debugLog('Analytics', () => [
    'captureAndSend',
    '🚀 Sent beacon',
    { elem, ...values },
  ])

  return true
}

/**
 * Finds a Launch rule, either with the tracked element or the rule's name.
 *
 * @param $elemOrRuleName
 * @returns
 */
function findLaunchRule($elemOrRuleName: JQuery | string) {
  const { LaunchRules } = RAConfig.analytics

  if (LaunchRules) {
    const ruleNames = keys(LaunchRules)

    if (typeof $elemOrRuleName === 'string') {
      const launchRuleName = $elemOrRuleName as string

      if (launchRuleName in LaunchRules) {
        return LaunchRules[launchRuleName]
      }
    } else {
      const $elem = $elemOrRuleName as JQuery

      for (let i = 0; i < ruleNames.length; i++) {
        const rule = LaunchRules[ruleNames[i]]
        if ($elem.is(rule.selector)) {
          return rule
        }
      }
    }
  }

  console.warn(
    'could not find launch rule for the following element or rule name, was initAnalyticsIntegration called?',
    $elemOrRuleName
  )

  return null
}

/**
 * Gets globally-defined evars and props sent with all interactions.
 *
 * @param launchRule Launch rule with getters for custom evars/props.
 * @param trackRuleContext Track rule name and resolved modifiers.
 * @returns
 */
function getGlobalEvarPropMaps(
  launchRule: LaunchRuleInterface,
  trackRuleContext: TrackRuleContextInterface
): { evarMap: StringMap; propMap: StringMap } {
  const { getEvarMap = () => {}, getPropMap = () => {} } = launchRule

  return {
    evarMap: {
      ...getGlobalEvarMap(trackRuleContext),
      ...getEvarMap(trackRuleContext),
    },
    propMap: {
      ...getGlobalPropMap(trackRuleContext),
      ...getPropMap(trackRuleContext),
    },
  }
}

/**
 * Gets globally-defined evars sent with all interactions.
 *
 * @param trackRuleContext Track rule name and resolved modifiers.
 * @returns
 */
const getGlobalEvarMap: CaptureFunctionInterface = (trackRuleContext) => {
  const {
    _satellite: _s,
    digitalData: { user },
  }: AnalyticsWindowInterface = window as any

  const { webAccountIdCookieName } = EnvConfigLoginLib
  const { name, modifiers } = trackRuleContext
  const { trackRules } = RAConfig.analytics
  const { location = trackRuleContext.location } = trackRules[name]

  return {
    eVar24: user.attributes.loginStatus ? 'registered' : 'guest',
    eVar25: _s.readCookie(webAccountIdCookieName),
    eVar65: location || '',
    eVar70: [location].concat(modifiers).join(':'),
  }
}

/**
 * Gets globally-defined props sent with all interactions.
 *
 * @param trackRuleContext Track rule name and resolved modifiers.
 * @returns
 */
const getGlobalPropMap: CaptureFunctionInterface = (_trackRuleContext) => {
  return {
    prop2: window.location.href,
    prop24: 'D=v24', // Means: set prop to the same value as evar24 (v24)
    prop25: 'D=v25',
    prop65: 'D=v65',
    prop70: 'D=v70',
  }
}

/**
 * Maps global modifier placeholders with functions to extract the correct value
 * from the current element at runtime. These placeholders are wrapped in
 * [square brackets] to disambiguate from other custom placeholders wrapped in
 * {curly braces} that must be provided by the developer on the element with a
 * `data-track-*` attribute.
 */
export const GlobalPlaceholders: FunctionMap<JQuery, string> = {
  '[targetValue]': ($this) => $this.val() as string,
  '[targetText]': ($this) => $this.children(':selected').text(),
  '[toggleButtonValue]': ($this) =>
    $this.closest('.toggle-button').find('input[type=hidden]').val() as string,
  '[buttonToggleValue]': ($this) =>
    $this.find('button.-selected').val() as string,
  '[buttonToggleText]': ($this) =>
    $this.find('button.-selected').text() as string,
}

/**
 * Regular expression for identifying and replacing a custom placeholder used to
 * capture application-specific details for a particular track rule.
 */
export const CUSTOM_PLACEHOLDER = /\{(.*)\}/

/**
 * Resolves modifier placeholders to the final set for send.
 *
 * @param modifiers Modifier array with placeholders as configured.
 * @param placeholders Values for custom placeholder substitution in modifiers.
 *   For example, if a modifier is `{chartName}`, it will be replaced by
 *   `placeholders.chartName`.
 * @returns
 */
export function resolveModifiers(
  modifiers: string[] | undefined,
  placeholders?: StringMap
): string[] {
  return modifiers
    ? modifiers.map((m) => {
        const matches = m.match(CUSTOM_PLACEHOLDER)
        return matches && placeholders ? placeholders[matches[1]] : m
      })
    : []
}
