/** Simple wrapper around local storage */

export const StorageTypes = {
  SESSION_STORAGE: 'sessionStorage',
  LOCAL_STORAGE: 'localStorage',
}

function hasStorage(type) {
  try {
    window[type].setItem('foo', true)
    window[type].removeItem('foo')

    return true
  } catch (x) {
    return false
  }
}

const FALLBACK = {}

let defaultStorage = hasStorage(StorageTypes.LOCAL_STORAGE)
  ? window.localStorage
  : {}

export function setDefaultStorageType(type) {
  defaultStorage = hasStorage(type) ? window[type] : defaultStorage
}

function getStorage(type) {
  if (type) {
    return hasStorage(type) ? window[type] : defaultStorage
  } else {
    return defaultStorage
  }
}

export function set(key, value, type) {
  let storage = getStorage(type)

  try {
    storage[key] = JSON.stringify(value)
  } catch (x) {
    // If there is an issue, start working from the fallback
    console.warn('There was an error setting ' + value + ' for ' + key)

    if (storage !== FALLBACK) {
      defaultStorage = FALLBACK
      set(key, value)
    }
  }

  return value
}

export function get(key, defaultValue, type) {
  let storage = getStorage(type)

  const value = JSON.parse(storage[key] || null)

  return value == null ? defaultValue : value
}

export function remove(key, type) {
  let storage = getStorage(type)

  try {
    delete storage[key]
  } catch (x) {
    // If there is an issue, start working from the fallback
    console.warn('There was an error removing ' + key)

    if (storage !== FALLBACK) {
      defaultStorage = FALLBACK
      remove(key)
    }
  }
}

export function removeWithPrefix(type, prefix, exclude = []) {
  let storage = getStorage(type)

  let keys = Object.keys(storage).filter(
    (k) => k.startsWith(prefix) && !exclude.includes(k)
  )

  keys.map((k) => remove(k, type))
}

export function clear(type) {
  let storage = getStorage(type)

  try {
    storage.clear()
  } catch (x) {
    defaultStorage = {}
  }
}

export function update(key, fn, fallback, type) {
  let value = get(key, fallback, type)

  set(key, fn(value), type)

  return get(key, type)
}

export default { get, set, update, remove, removeWithPrefix, clear }
