import { CommonProps } from '@ra/lib-common/types/react'
import React, { SVGProps } from 'react'
import { dangerousHtml } from '../../html'

export type SvgProps = CommonProps<
  {
    viewBox?: string
    display?: string
    size?: string | number
    width?: string | number
    height?: string | number
    preserveAspectRatio?: string
    content?: string
  } & SVGProps<SVGSVGElement>
>

function Svg({
  size,
  width = size,
  height = size,
  viewBox = `0 0 ${width} ${height}`,
  preserveAspectRatio = 'none',
  display = 'block',
  className,
  style,
  content,
  children,
  ...props
}: SvgProps) {
  return content ? (
    <svg
      display={display}
      className={className}
      style={style}
      viewBox={viewBox}
      width={width}
      height={height}
      preserveAspectRatio={preserveAspectRatio}
      {...props}
      {...dangerousHtml(content)}
    />
  ) : (
    <svg
      display={display}
      className={className}
      style={style}
      viewBox={viewBox}
      width={width}
      height={height}
      {...props}
    >
      {children}
    </svg>
  )
}

export default Svg
