import { trackClickAttribute } from '@ra/lib-common/analytics/attribute'
import AAILogo from '@ra/lib-common/components/svg/logo/aai-logo'
import { AlertTriangle } from 'lucide-react'
import React from 'react'

/**
 * Error boundary component for displaying a friendly, conciliatory message with
 * a button to reset the app should an unhandlable error break the app.
 */
class ErrorBoundary extends React.Component<
  {},
  { error?: Error; errorInfo?: React.ErrorInfo }
> {
  constructor(props: {}) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo })
  }

  render() {
    return this.state.error
      ? this.renderOops(this.state.error as Error)
      : this.props.children
  }

  renderOops(error: Error) {
    const { errorInfo } = this.state

    return (
      <div className="app">
        <div className="dashboard">
          <div className="dashboard__header">
            <button
              className="header__logo"
              onClick={this.onReset}
              {...trackClickAttribute('RA Logo')}
            >
              <AAILogo className="header__logo" />
            </button>
          </div>

          <div className="dashboard__body">
            <div className="body__error">
              <div className="error__title">
                <AlertTriangle size={30} />
                Something Unexpected Happened
              </div>

              <div className="error__description">
                We're sorry to slow you down.
              </div>

              <button className="error__reset" onClick={this.onReset}>
                Reset the App
              </button>

              <div className="error__spacer" />

              <div className="error__details">
                {error.toString()}

                <br />

                {errorInfo && error.stack?.toString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onReset() {
    const { origin, pathname } = window.location
    window.location.href = `${origin}${pathname}`
  }
}

export default ErrorBoundary
