import RAConfig from '@ra/lib-config'
import isObject from 'lodash/isObject'
import { CUSTOM_PLACEHOLDER, resolveModifiers } from './launch'
import {
  TinyTrackRuleInterface,
  TrackAttributeValueFunction,
  TrackChangeAttributeFunction,
  TrackClickAttributeFunction,
  TrackConfigInterface,
} from './types'

/**
 * Returns an object with a `data-track-click` property created from
 * configuration. Spread into a element's props for tracking its 'click' event.
 * Accepts `undefined` arguments to provide flexibility to the caller.
 *
 * @param nameOrConfig Name of the track rule for this item; must correspond to
 *   a key in the `trackRules` entry in `analytics.config.json`. Or pass a
 *   single `TrackConfigInterface` argument.
 * @param modifierValues Values for placeholder substitution in modifiers. For
 *   example, if a modifier is `{chartName}`, it will be replaced by
 *   `placeholders.chartName`.
 * @returns
 */
export const trackClickAttribute: TrackClickAttributeFunction = (
  nameOrConfig,
  modifierValues
) => {
  return {
    'data-track-click': getTrackAttributeValue(nameOrConfig, modifierValues),
  }
}

/**
 * Returns an object with a `data-track-change` property created from
 * configuration. Spread into a element's props for tracking its 'change' event.
 * Accepts `undefined` arguments to provide flexibility to the caller.
 *
 * @param nameOrConfig Name of the track rule for this item; must correspond to
 *   a key in the `trackRules` entry in `analytics.config.json`. Or pass a
 *   single `TrackConfigInterface` argument.
 * @param modifierValues Values for placeholder substitution in modifiers. For
 *   example, if a modifier is `{chartName}`, it will be replaced by
 *   `placeholders.chartName`.
 * @returns
 */
export const trackChangeAttribute: TrackChangeAttributeFunction = (
  nameOrConfig,
  modifierValues
) => {
  return {
    'data-track-change': getTrackAttributeValue(nameOrConfig, modifierValues),
  }
}

/**
 * Gets the value expected for a `data-track-*` attribute on tracked element.
 * Accepts `undefined` arguments to provide flexibility to the caller.
 *
 * @param nameOrConfig Name of the track rule for this item; must correspond to
 *   a key in the `trackRules` entry in `analytics.config.json`. Or pass a
 *   single `TrackConfigInterface` argument.
 * @param modifierValues Values for placeholder substitution in modifiers. For
 *   example, if a modifier is `{chartName}`, it will be replaced by
 *   `placeholders.chartName`. If the first parameter is a
 *   `TrackConfigInterface`, its `placeholders` will be merged/overridden with
 *   this argument.
 * @returns
 */
export const getTrackAttributeValue: TrackAttributeValueFunction = (
  nameOrConfig,
  modifierValues = {}
) => {
  let name: string | undefined = undefined

  if (isObject(nameOrConfig)) {
    const input = nameOrConfig as TrackConfigInterface
    name = input.name as string
    modifierValues = { ...input.placeholders, ...modifierValues }
  } else {
    name = nameOrConfig
  }

  if (!name) {
    return undefined
  }

  const { trackRules } = RAConfig.analytics
  if (name in trackRules === false) {
    console.error(`Click tracking config not found for '${name}'.`)
    return undefined
  }

  const { modifiers } = trackRules[name]

  if (
    modifiers &&
    modifiers.some((m) => CUSTOM_PLACEHOLDER.test(m)) &&
    !modifierValues
  ) {
    throw new Error(
      `[${name}] The modifierValues arg is required when modifiers contain placeholders.`
    )
  }

  const tiny: TinyTrackRuleInterface = {
    n: name,
    m: resolveModifiers(modifiers, modifierValues),
  }

  return JSON.stringify(tiny)
}
