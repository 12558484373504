import './preamble'

import React, { lazy } from 'react'
import DOM from 'react-dom'
import ErrorBoundary from './error'
import { Preload } from './preload'

/**
 * Load root component asynchronously to help split bundle into smaller sizes.
 * Use minimum 2 second delay to allow preload to be seen without flash.
 */
const Root = lazy(async () => {
  const [Root] = await Promise.all([
    import('./root'),
    new Promise((resolve) => setTimeout(resolve, 2000)),
  ])

  return Root
})

DOM.render(
  <ErrorBoundary>
    <React.Suspense fallback={<Preload />}>
      <Root />
    </React.Suspense>
  </ErrorBoundary>,
  document.querySelector('#root')
)
