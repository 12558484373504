import qs from 'query-string'

export let SHOW_DEBUG_PANEL = false
export const ALL_DEBUG_MODES: string[] = []
export const ACTIVE_DEBUG_MODES: string[] = []

let initialized = false

/** Initializes debug modes; looks for `debug=mode1,mode2` in the query string. */
function init() {
  if (initialized) return

  const query = qs.parse(window.location.search, { arrayFormat: 'comma' })

  if ('debug' in query || isNonProduction()) {
    SHOW_DEBUG_PANEL = true

    if ('debug' in query) {
      ACTIVE_DEBUG_MODES.push(...[query.debug as string].flat())
    }

    if (!isDebugAll()) {
      const modes = ACTIVE_DEBUG_MODES.join(', ')
      console.log(
        `%c🐞 Debugging enabled for modes:`,
        'font-weight: 600',
        modes ? modes : '(none)'
      )
    } else {
      console.log('%c🐞 Debugging enabled for all modes.', 'font-weight: 600')
    }
  }

  initialized = true
}

/**
 * Gets a boolean indicating whether code is running on localhost or in the live
 * Stage environment.
 */
function isNonProduction() {
  return false
  const { origin } = window.location
  return origin.includes('localhost') || origin.includes('stginteractive')
}

/** Gets a boolean indicating whether all debug modes should be active. */
function isDebugAll() {
  return ACTIVE_DEBUG_MODES.includes('true') || ACTIVE_DEBUG_MODES.includes('*')
}

/**
 * Returns true if any of the debug modes are active.
 *
 * @param modes One or more debug mode strings; as arguments.
 * @returns
 */
export function isDebug(...modes: string[]): boolean {
  if (modes.length === 0) {
    return window.location.search.includes('debug')
  }

  modes = captureModes(modes)
  init()

  return (
    isDebugAll() ||
    ACTIVE_DEBUG_MODES.some((mode) => mode && modes.includes(mode))
  )
}

/**
 * Logs a message to the console when one or more debug modes are active.
 *
 * @param modes A single debug mode string, or an array of multiple debug mode
 *   strings.
 * @param logArgsFn A function that returns an array of arguments to pass into
 *   `console.log`; only executed when debug mode(s) are active.
 */
export function debugLog(
  mode: string | string[],
  logArgsFn: () => any[]
): void {
  const modes = captureModes([mode].flat())

  if (isDebug(...modes)) {
    console.log(
      ...[
        `%c🐞 ${modes.join(', ')}`,
        'font-weight: 600',
        ...logArgsFn(),
        { callstack: getStack() },
      ]
    )
  }
}

/**
 * Gets a function for logging a message with a preset debug mode.
 *
 * @param mode A single debug mode string.
 * @returns A function that takes a single logArgsFn parameter, a function that
 *   returns an array of arguments to pass into `console.log`; only executed
 *   when debug mode(s) are active.
 */
export const getDebugLog = (mode: string) => (logArgsFn: () => any[]) =>
  debugLog(mode, logArgsFn)

/**
 * Uses a thrown error to derive current stack; helpful for locating site of
 * debugLog call.
 */
function getStack() {
  try {
    throw new Error()
  } catch (e) {
    return e.stack.split('\n')
  }
}

/**
 * Logs a table-able object to the console when one or more debug modes are
 * active.
 *
 * @param mode A single debug mode string, or any array of multiple debug mode
 *   strings.
 * @param table Object that can be represented as a table.
 */
export function debugTable(mode: string | string[], table: any) {
  const modes = captureModes([mode].flat())

  if (isDebug(...modes)) {
    console.table(table)
  }
}

/**
 * Captures debug modes as they are used by `logDebug` or `isDebug` calls at
 * runtime so that they can be made available to the Debug panel.
 */
function captureModes(modes: string[]) {
  modes
    .filter((m) => !ALL_DEBUG_MODES.includes(m))
    .forEach((m) => ALL_DEBUG_MODES.push(m))
  return modes
}

/**
 * Helper for outputting debug data in an object with alpha-prefixed keys to
 * ensure logical order in console. Final output key is prefixed with 'z_'.
 */
export function debugAlphaOutput(
  steps: { [key: string]: any },
  final: { [key: string]: any }
) {
  const stepKeys = Object.keys(steps)
  const alphaSteps = stepKeys.reduce((map, key, index) => {
    map[`${String.fromCharCode(97 + index)}_${key}`] = steps[key]
    return map
  }, {} as { [key: string]: any })

  const finalKeys = Object.keys(final)
  const alphaFinal = finalKeys.reduce((map, key) => {
    map[`z_${key}`] = final[key]
    return map
  }, {} as { [key: string]: any })

  return { ...alphaSteps, ...alphaFinal }
}
